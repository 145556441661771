'use strict';

// Sass Import
import '../Sass/style.scss';

// TOPへ戻る
// セレクタ名（.pagetop）に一致する要素を取得
const pagetop_btn = document.getElementById('js-pageTop');

if (pagetop_btn) {
  // .pagetopをクリックしたら
  pagetop_btn.addEventListener('click', scroll_top);
}

// ページ上部へスムーズに移動
function scroll_top() {
  window.scroll({ top: 0, behavior: 'smooth' });
}

// スクロールされたら表示
window.addEventListener('scroll', scroll_event);
function scroll_event() {
  if (window.scrollY > 100) {
    if (pagetop_btn) {
      pagetop_btn.style.opacity = '1';
    }
  } else if (window.scrollY < 100) {
    if (pagetop_btn) {
      pagetop_btn.style.opacity = '0';
    }
  }
}
// ハンバーガーメニュー
const mobileBtn = document.querySelector('.p-header--mobileBtn');

if (mobileBtn) {
  mobileBtn.addEventListener('click', function () {
    // ボタンがクリックされたら
    this.classList.toggle('js-active'); // ボタン自身に active クラスを付与または除去
    const mobileMenu = document.querySelector('#p-header--mobileMenu');
    if (mobileMenu) {
      mobileMenu.classList.toggle('js-panelactive'); // ナビゲーションに panelactive クラスを付与または除去
    }
  });
}

const navLinks = document.querySelectorAll('#p-header--mobileMenu a');
for (const link of Array.from(navLinks)) {
  link.addEventListener('click', function () {
    // ナビゲーションのリンクがクリックされたら
    const mobileBtn = document.querySelector('.p-header--mobileBtn');
    if (mobileBtn) {
      mobileBtn.classList.remove('js-active'); // ボタンの active クラスを除去
    }
    const mobileMenu = document.querySelector('#p-header--mobileMenu');
    if (mobileMenu) {
      mobileMenu.classList.remove('js-panelactive'); // ナビゲーションの panelactive クラスを除去
    }
  });
}

// プログレスバー要素を取得
const progressEl = document.querySelector('.swiper-pagination');

// Swiperを初期化
const swiper = new Swiper('.swiper', {
  centeredSlides: true,
  autoHeight: true,
  slidesPerView: 1.5,
  slidesPerGroup: 1,
  simulateTouch: false,
  spaceBetween: 25,

  // ループ有効化
  loop: true,

  // ページネーション設定
  pagination: {
    el: progressEl, // プログレスバー要素を指定
    type: 'progressbar',
  },

  // ナビゲーションボタン設定
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  //　ブレイクポイント
  breakpoints: {
    550: {
      slidesPerView: 2,
      spaceBetween: 40,
      slidesPerGroup: 1,
    },
    767: {
      slidesPerView: 3.5,
      spaceBetween: 40,
      slidesPerGroup: 1,
    },
    1100: {
      slidesPerView: 4,
      slidesPerGroup: 1,
    },
    1400: {
      slidesPerView: 4.4,
      spaceBetween: 40,
      slidesPerGroup: 1,
    },
    1500: {
      slidesPerView: 6,
      spaceBetween: 40,
      slidesPerGroup: 1,
    },
  },
});
